import React from "react";
import { Link } from "gatsby";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { Container, Row, Col } from "react-bootstrap";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Arrow from "../images/arrow.inline.svg";
import Read from "../images/read.inline.svg";
import Warrow from "../images/white_arrow.inline.svg";
import LeverIdIll from "../images/Lever-ID-illustration.inline.svg";

function showMore() {
  document.getElementById("hiddensection").classList.add("show");
  document.getElementById("show-more").classList.add("hidden");
  document.getElementById("show-less").classList.remove("hidden");
  document.getElementsByClassName("overflowefect")[0].classList.add("noefect");
}
function showLess() {
  document.getElementById("hiddensection").classList.remove("show");
  document.getElementById("show-more").classList.remove("hidden");
  document.getElementById("show-less").classList.add("hidden");
  document
    .getElementsByClassName("overflowefect")[0]
    .classList.remove("noefect");
  window.scrollTo({ top: 0, behavior: "smooth" });
}

function IndexPage({ data, pageContext: { lang, currentPath } }) {
  return (
    <Layout>
      <SEO
        title="About LeverID"
        description="LeverID is a Post-Quantum Capable Digital Identity and Signature Platform that’s designed to provide a single universal authentication and signing medium for government and business verticals."
        image="https://www.leverid.com/thumbnail-new.png"
      />
      <Container className="concept-above">
        <Row>
          <Col>
            <h1>The Concept Behind LeverID</h1>
            <p class="download-link">
              <a href="/Product_Overview_pager.pdf" target="_blank">
                Download Product Overview <Warrow />
              </a>
            </p>
          </Col>
        </Row>
        <Row className="short-desc overflowefect">
          <Col>
            <p>
              LeverID is a <strong>Post-Quantum Capable</strong> Digital
              Identity and Signature Platform that&rsquo;s designed to provide{" "}
              <strong>
                a single universal authentication and signing medium for
                government and business verticals.&nbsp;
              </strong>
            </p>
            <p>
              If individuals happen to hold a Government-issued identification
              document (either a passport or some sort of identity card),
              LeverID can be added on top of it, providing a true digital
              identity.
            </p>
            <p>
              Since{" "}
              <strong>LeverID is offered as a white-label solution, </strong>
              countries and organizations can customize (for example, rename and
              rebrand) the platform in order to suit their needs.
            </p>
            <p>
              In order to respond to the call of modern technology trends,
              LeverID was{" "}
              <strong>designed as a mobile-first application </strong>making it{" "}
              <strong>usable wherever you happen to find yourself, </strong>
              provided you have access to an internet connection.
            </p>
          </Col>
        </Row>
        <Row className="show-more-row">
          <Col>
            <div id="show-more" onClick={showMore}>
              Read more
              <Read />
            </div>
          </Col>
        </Row>
        <Row id="hiddensection" className="short-desc hidden">
          <Col>
            <h2>
              <strong>What are the benefits of LeverID?</strong>
            </h2>
            <p>
              LeverID has several benefits both for governments and businesses.
              While some of them are universal, others are specific to each.
            </p>
            <h4>
              <strong>The Universal Benefits</strong>
              <strong> of the LeverID solution are:</strong>
              <strong></strong>
            </h4>
            <ul>
              <li>
                <strong>Post-Quantum Capable Design</strong>: The advent of
                quantum computing brings new security challenges. With this in
                mind, LeverID has been tailored to feature quantum capability.
              </li>
              <li>
                <strong>Largely Scalable</strong>: Product design and
                infrastructure configuration enable vast scalability even by
                utilization of hundreds of millions of users.
              </li>
              <li>
                <strong>Attack-Tolerant and Reliable: </strong>LeverID features
                distributed private key implementation, ensuring no service
                breaks and reliable defense against threats.
              </li>
              <li>
                <strong>Fast Transaction Speed:</strong> Whether it&rsquo;s
                authentication or signing, transactions speeds remain
                consistently swifter than any RSA-based solution.
              </li>
              <li>
                <strong>Ease of Integration</strong>: Being technically
                documented, ease of integration is enabled through API or a
                software development kit (SDK) for clients wishing to integrate
                LeverID.
              </li>
              <li>
                <strong>Mobile-First Design</strong>: Designed with mobile users
                in mind, our solution allows our users to effortlessly
                authenticate and sign from anywhere in the world.
              </li>
            </ul>
            <h4>
              <strong>Governments </strong>
              <strong>
                have very specific needs and LeverID provides them with the
                following additional benefits:
              </strong>
              <strong></strong>
            </h4>
            <ul>
              <li>
                <strong>Fully Customizable Approach</strong>: A modular solution
                with white label readiness to ensure the product can be
                configured to suit a specific country.
              </li>
              <li>
                <strong>Universal Login:</strong> Enable citizens and residents
                access to different digital government services via one
                universal login.
              </li>
            </ul>
            <h4>
              <strong>Businesses</strong>
              <strong>
                {" "}
                on the other hand are likely to be interested in the following:{" "}
              </strong>
              <strong></strong>
            </h4>
            <ul>
              <li>
                <strong>Enhanced Employee Onboarding:</strong> Providing
                employees from different countries with single authentication
                and controlled access to relevant information and documentation.
              </li>
              <li>
                <strong>Data Security &amp; Ownership</strong>: Employee data is
                securely stored and managed in-line with GDPR regulations, and
                is protected from unscrupulous access.
              </li>
              <li>
                <strong>Flexible and Scalable:</strong> Whether your company
                employs 10 or 10 million, is domestic or global, our solution
                scales effortlessly to meet your demands.
              </li>
            </ul>
            <p>
              <strong>Universal Login:</strong> Enable citizens and residents
              access to different digital government services via one universal
              login.
            </p>
            <h2>
              <strong>Solving a post-quantum cryptography problem</strong>
            </h2>
            <p>
              Although LeverID offers a long list of benefits over the other
              Digital Identity solutions existing in today&rsquo;s marketplace,
              LeverID&rsquo;s{" "}
              <strong>
                capability to deal with the issue of Post-Quantum Cryptography
              </strong>{" "}
              is probably the most significant.&nbsp;
            </p>
            <p>
              80% of the world&rsquo;s encryption is based on the RSA
              (Rivest&ndash;Shamir&ndash;Adleman) cryptosystem from 1977. The
              keys for the RSA algorithm are generated&nbsp;by choosing two
              distinct prime numbers and multiplying them.&nbsp;
            </p>
            <p>
              In 1994 an American mathematician Peter Shor discovered a quantum
              algorithm that factors large trapdoor function numbers, something
              that their traditional computer counterparts were not doing as
              efficiently. This is one step closer to cracking RSA encryption.
              In other words, it is easy to have two prime numbers for example
              199 and 227 multiply them and get 45173, but it is very difficult
              to calculate the function in reverse. However, this is{" "}
              <strong>not &ldquo;</strong>
              <strong>
                <em>mission impossible</em>
              </strong>
              <strong>&rdquo; for quantum computer algorithms</strong>.
            </p>
            <p>
              Cryptography works because some problems are hard to solve. In the
              classic RSA examples, the problem-that-we-rely-on-as-being-hard is
              factoring a product of two large prime numbers. In the{" "}
              <strong>elliptic curve</strong> world, the
              problem-that-we-rely-on-as-being-hard is known as the&nbsp;
              <em>discrete logarithm problem</em>. This is an equation for which
              a special way has been developed to change the equation by any
              factor. But if you now remove the multiplier from the equation,
              but keep the answer, it becomes almost impossible to solve the
              puzzle. The randomness aspect of the equation also changes
              depending on the multiplier. Thus, it takes exponentially more
              time and energy to solve such an equation. The time needed is in
              correlation with the strength of that equation and that adds
              security to the platform.&nbsp;
            </p>
            <p>
              <strong>
                LeverID is the next generation of digital authentication and
                signature platforms
              </strong>
              . While we are constantly working on designing a platform that is
              capable of utilizing standard crypto specifications that are
              available today, we are also investing heavily in researching
              post-quantum proof technologies and cryptography standards.&nbsp;
            </p>
            <p>
              The LeverID platform has been designed in a way that enables us to
              implement multiple cryptography standards. This will include{" "}
              <strong>post-quantum resilient cryptography standards</strong>{" "}
              once they&rsquo;ve been established and agreed upon,
              internationally.
            </p>
            <p>
              Quantum computing is already a reality today. For example, the IBM
              65 qubit computer. The reality of quantum computers brute-forcing
              trapdoor functions is still quite far off (around 10-15 years),
              which is why Levercode is already investigating{" "}
              <strong>
                possible solutions for governments and critical infrastructure
                clients
              </strong>
              , in order for them to{" "}
              <strong>secure their data for the next 25 years,</strong> even if
              quantum computers advance in regard to qubit volume.
            </p>
            <p>
              Some samples of{" "}
              <strong>affected mission-critical industries</strong> are:
            </p>
            <ul>
              <li>
                e-Government and Trust Service providers in the field of eIDAS
                who rely on secure communication channels, electronic
                signatures, and data validation.
              </li>
              <li>
                The banking industry with credit and debit cards as well as
                (mobile) payment applications.
              </li>
              <li>
                Digital Health platforms that process encrypted sensitive
                personal information.&nbsp;
              </li>
              <li>
                The Energy sector with its remote metering and other relevant
                data management systems.
              </li>
            </ul>
            <h2>
              <strong>How does LeverID work?</strong>
            </h2>
            <p>
              The <strong>first phase</strong> of the LeverID solution is for
              the individual user to <strong>create a digital identity</strong>.
              After the user's request to create a LeverID identity is made,
              LeverID will validate user eligibility, either through a third
              party &lsquo;Know your Customer&rsquo; (KYC) provider, or a
              Government Registration Authority.
            </p>
            <p>
              The <strong>second phase</strong> of LeverID begins when a user{" "}
              <strong>initiates an authentication or a signing request</strong>{" "}
              via LeverID via a &lsquo;Relaying Party&rsquo; (a service provider
              or vendor). Depending on the user&rsquo;s request, either an{" "}
              <strong>authentication</strong> certificate or a{" "}
              <strong>signing</strong> certificate is validated against the
              LeverID database.&nbsp;
            </p>
            <p>
              If eligibility is met and confirmed, LeverID initiates the process
              of creating an authentication and signing certificate for the
              user. Once this process is followed through, the user obtains
              valid certificates in order to authenticate and sign digitally.{" "}
            </p>
            <p>
              The LeverID solution consists of{" "}
              <strong>three principal components</strong>:
            </p>
            <ul>
              <li>Server application and Hardware Security Module (HSM)</li>
              <li>Mobile Device Application</li>
              <li>
                Application Programming Interface (API) for Relaying Parties
                (RP)
              </li>
            </ul>
            <p>
              For a higher level of security, both the user and server have{" "}
              <strong>independent private keys</strong>. The server and
              user&rsquo;s mobile device both sign the document or
              authentication challenge independently with their private keys.
              These signatures are then cryptographically combined into a
              standard verifiable digital signature that verifies against the
              user&rsquo;s public key certificate.&nbsp;
            </p>
            <p>
              In addition, <strong>two-factor authentication</strong> is used.
              Both the user&rsquo;s mobile device and the server use independent
              mechanisms to authenticate the user.&nbsp;
            </p>
            <p>
              It is important to differentiate between{" "}
              <strong>component responsibility</strong> when talking about a{" "}
              <strong>state/country or a private company</strong> in terms of
              where the accountability of the components will lay. The{" "}
              <strong>critical components</strong> of LeverID digital
              authentication and signing platform are:
            </p>
            <ul>
              <li>Hardware Security Module and server application</li>
              <li>Mobile application&nbsp;</li>
              <li>API for relaying parties&nbsp;</li>
              <li>Registration Authority (RA)</li>
              <li>Certificate Authority (CA)</li>
              <li>Verification Authority (VA)&nbsp;</li>
            </ul>
            <div className="content-image">
              <LeverIdIll />
            </div>
            <p>
              <strong>In the case of state/country digital identity</strong>,
              all six components will be under the control of the state that is
              responsible for the identities. The LeverID infrastructure will be
              configured and set up on-site and control can be handed over to
              responsible government institutions.
            </p>
            <p>
              <strong>In the case of private digital identity</strong>, the
              accountability will lay on the LeverID certified infrastructure.
              In order to provide LeverID with identities, the KYC service
              provider&rsquo;s information is used via an API. LeverID will
              continue to validate certificates for authentication and signing
              requests.
            </p>
            <p>
              If certificates are proven to be valid, the user will be issued a
              challenge code through LeverID. If the challenge codes match, the
              user is prompted to enter their 4-digit authentication PIN or
              5-digit signing PIN. If these PINs are then proven valid,
              authentication or signing can take place depending on the initial
              request.
            </p>
            <h2>
              <strong>Certificates:</strong>
            </h2>
            <p>
              LeverID is Designed to comply with the following standards in
              order to offer a trusted product to all our customers:
            </p>
            <ul>
              <li>eIDAS</li>
              <li>Timestamping</li>
            </ul>
            <p>
              The certification process is ongoing and certificates are
              currently pending.
            </p>
          </Col>
        </Row>
        <Row className="show-more-row">
          <Col>
            <div id="show-less" onClick={showLess} className="hidden">
              Show less
              <Read />
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="team-wrap">
        <Container>
          <Row className="teamtitle">
            <h2>Our core team</h2>
            <p>
              The team behind LeverID largely extends the flanks of{" "}
              <strong>Levercode</strong>, the company responsible for the
              creation of the platform itself.
            </p>
            <p>
              Based on a{" "}
              <strong>
                Partnership Agreement with Tallinn University of Technology
                (TalTech)
              </strong>
              , we have also involved world-renowned Associated Experts from
              institutes of computer science and software science, as well as a
              pantheon of{" "}
              <strong>
                leading industry experts from other technology companies.
              </strong>
            </p>
          </Row>
          <Row className="teamrow">
            <Col>
              <div className="teamimg">
                <Img fluid={data.gustav.childImageSharp.fluid} />
                <div className="person-data">
                  <h4>Gustav Poola</h4>
                  <p>CEO, MSc</p>
                  <div className="person-desc">
                    <p>
                      Close to 30 years of diverse experience in the IT
                      technologies, e-platforms, board level integration, large
                      infrastructure development, risk management, and computer
                      science technology markets. He holds international SCRUM
                      Master, Data Protection Officer (DPO), and Money
                      Laundering Risk in Private Banking certificates. Co-author
                      of several patents.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <div className="teamimg">
                <Img fluid={data.oskar.childImageSharp.fluid} />
                <div className="person-data">
                  <h4>Oskar Poola</h4>
                  <p>CTO, MSc</p>
                  <div className="person-desc">
                    <p>
                      Expert of Analysis of Digital Integrity for Cloud Service
                      Provider platforms. He also excels in Government and
                      commercial e-Governance solutions, technologies, and
                      methods of implementation as well as and digital
                      integrity-oriented application solutions.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <div className="teamimg">
                <Img fluid={data.priit.childImageSharp.fluid} />
                <div className="person-data">
                  <h4>Priit Raspel</h4>
                  <p>Chief Data Architect, MSc</p>
                  <div className="person-desc">
                    <p>
                      30 years of experience in IT with the primary focus in
                      data, system architecture and modelling. Coordinated
                      Estonian eID architecture development as Chief Architect
                      for Estonian Information Systems Authority and in capacity
                      of Chief Architect for Ministry of Social Affairs, created
                      data architecture of Estonian e-Health ecosystem and
                      Patient Portal. Expert in commercial banking and insurance
                      sector IT architecture.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <div className="teamimg">
                <Img fluid={data.tarmo.childImageSharp.fluid} />
                <div className="person-data">
                  <h4>Tarmo Kõuhkna</h4>
                  <p>Head of Levercode Lab & Research, MBA</p>
                  <div className="person-desc">
                    <p>
                      Entrepreneurship professional, Kõuhkna is expert in
                      medical device, mobile communication, renewable energy,
                      and environment industries. Currently, he is engaged in
                      the fields of e-governance, digital identity, and
                      healthcare data systems.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <div className="teamimg">
                <Img fluid={data.kalle.childImageSharp.fluid} />
                <div className="person-data">
                  <h4>Kalle Kuuspalu</h4>
                  <p>System Process Architect, MSc</p>
                  <div className="person-desc">
                    <p>
                      Seasoned international technology expert and consultant on
                      integrating IT systems and optimizing workflows by
                      different technologies like Electronic Data Interchange
                      and Process Mining.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <div className="teamimg">
                <Img fluid={data.sergei.childImageSharp.fluid} />
                <div className="person-data">
                  <h4>Sergei Zamsharski</h4>
                  <p>Chief Product Officer, B.Sc IT</p>
                  <div className="person-desc">
                    <p>
                      Seasoned product manager with strong technical background
                      on developing complete solutions in telecommunication,
                      logistics, banking and security areas.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <div className="teamimg">
                <Img fluid={data.raul.childImageSharp.fluid} />
                <div className="person-data">
                  <h4>Raul Vahisalu</h4>
                  <p>
                    Head of International Business Development, MBA & MSocSc
                  </p>
                  <div className="person-desc">
                    <p>
                      25+ years of experience in the telecom and IT industry,
                      managing development and implementation of high-tech
                      concepts and projects. Expert in value-added services
                      research and development, data acquisition and analysis of
                      B2G platforms as well as design and operation of eID and
                      Interoperability. Author of the patent in the field of
                      mobile parking.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <div className="teamimg">
                <Img fluid={data.mariann.childImageSharp.fluid} />
                <div className="person-data">
                  <h4>Mariann Kirsipuu</h4>
                  <p>Head of Compliance, MA</p>
                  <div className="person-desc">
                    <p>
                      Expert of law, compliance, and risk management in the
                      areas of data and identity management, identity documents,
                      e-Government, and e-services. Experience in drafting and
                      implementation of eIDAS (Regulation (EU) No 910/2014) as
                      well as creation of Estonian e-Residency program.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <div className="teamimg">
                <Img fluid={data.riho.childImageSharp.fluid} />
                <div className="person-data">
                  <h4>Riho Kruuv</h4>
                  <p>Head of Strategic Relations, MA</p>
                  <div className="person-desc">
                    <p>
                      Former diplomat and Estonian Ambassador, Kruuv is expert
                      in global affairs, public policy, Government relations,
                      Digital Transformation, and international business. Avid
                      promoter of e-Governance.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <div id="prefooter">
        <Container>
          <Row>
            <Col>
              <Img fluid={data.rihofull.childImageSharp.fluid} />
            </Col>
            <Col>
              <h3>Reach out to us</h3>
              <p>
                To learn more about how LeverID could serve your needs, please
                reach out using the button below.
              </p>
              <Link to="/contact">
                Contact us <Arrow />
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query {
    diagram: file(relativePath: { eq: "Illustration.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    gustav: file(relativePath: { eq: "gustav.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    oskar: file(relativePath: { eq: "oskar.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    priit: file(relativePath: { eq: "priit.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    tarmo: file(relativePath: { eq: "tarmo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    kalle: file(relativePath: { eq: "kalle.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sergei: file(relativePath: { eq: "sergei.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mariann: file(relativePath: { eq: "mariann.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    raul: file(relativePath: { eq: "raul.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    riho: file(relativePath: { eq: "riho.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    rihofull: file(relativePath: { eq: "riho_full.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
export default IndexPage;
